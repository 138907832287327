import React from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { AppContext } from "../../providers/appProvider";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";
import { Grid, Paper, Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ProductSlider from "../Slider/ProductSlider";
// import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import AccessTime from "@material-ui/icons/AccessTime";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { withTranslation } from "react-i18next";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import gray from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Rating from "@material-ui/lab/Rating";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PostAddIcon from '@material-ui/icons/PostAdd';
// import InputAdornment from "@material-ui/core/InputAdornment";

import PropTypes from "prop-types";

// import ShareIcon from "@material-ui/icons/Share";
import Collapse from "@material-ui/core/Collapse";

import Link from "@material-ui/core/Link";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Youtube from "../Video/youtube";
import moment from "moment";
import "moment/locale/uk";


import ItemsStepper from "./ItemsStepper";
import {TablePagination, Button, IconButton } from "@material-ui/core";

import ShareIcon from '@material-ui/icons/Share';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

const EGUID = "00000000-0000-0000-0000-000000000000";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        width: '100%',
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // overflow: 'hidden',
      },
    card: {
        padding: theme.spacing(2)
    },
    header: {
        fontSize: "1.9rem",
        fontWeight: 300,        
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "1.6rem",
        },
    },
    appBarBtn: {   
        padding: theme.spacing(0, 8, 1/2, 8),        
        boxShadow: "none",        
        backgroundColor: 'transparent',
        top: "auto",
        bottom: 0,        
     },
    toolbarBtn: {
        // minHeight: "38px",
    },
    paper: {
        padding: theme.spacing(1),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },

    grow: {
        flexGrow: 1,
    },
    breadcrumbs: {
        padding: theme.spacing(1),
    },
    link: {
        display: "flex",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
    },
    chipBtn: {
        marginRight: theme.spacing(2)
    },      
    chipBuyBtn: { 
        pading: theme.spacing(2),
        // marginRight: theme.spacing(2),
        // height: theme.spacing(6),
        // borderRadius: 32,
        // fontSize: "1rem"
    },
    attr: {
        fontSize: "1rem",
        padding: theme.spacing(1, 4),
    },
    block: {
        padding: theme.spacing(2),        
    },
    priceblock: {
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(2)
        },
    },
    tableAttr: {
        color: theme.palette.type === "light" ? gray[600]: gray[300], 
        "& tr" : {
            "& td": {
                verticalAlign: 'top',
                [theme.breakpoints.down("sm")]: {
                    fontSize: ".9rem",
                },                
                padding: theme.spacing(1/2, 1)
            }             
        }
    },
    tdAttr: {
        fontSize: '1.1rem'        
        // paddingLeft: theme.spacing(3)
    },
    tabReview: {
        paddingTop: theme.spacing(0),
        // paddinBottom: theme.spacing(2),
    },
    averageReview: {
        color: gray[500],
        padding: theme.spacing(0, 3),
    },
    // images: {
    //     padding: theme.spacing(0, 2),
    //     [theme.breakpoints.down("sm")]: {
    //         padding: theme.spacing(0),
    //     }
    // },
    SubstanceComposition:{
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          whiteSpace: "initial",
          width: "19rem",
          overflowX: "auto",
        },
        [theme.breakpoints.down("md")]: {       
          width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
          width: "100%",
        },
    },
    rating: {
        marginBottom: theme.spacing(3)
    },

    reviewbox: {
        padding: theme.spacing(2),
        // backgroundColor: gray[50],
        borderRadius: "16px"
    },

    reviewAuth: {
        marginLeft: theme.spacing(2),
        fontSize: ".9rem",
        textTransform: "capitalize"
    },

    buttons: {
        marginRight: theme.spacing(1),
    },
    
    specialprice: {
        position: "relative",
        width: 48,
        top: 1,
        left: -16,
        fontSize: "0.8rem",
        color: theme.palette.secondary.main,
        padding: theme.spacing(1* 0.2, 1),
        borderRadius: "0 16px 16px 0",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor:theme.palette.secondary.main,
        zIndex: 100,
        // border: "1px solid #f50057",
        
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        boxShadow: theme.palette.ambilight.boxshadow
        // right: theme.spacing(1),
    },

    priceBB: {        
        position: "absolute",
        top: 0,
        height: 22,  
        right: 0,
        // top: theme.spacing(1),
        // [theme.breakpoints.down("xs")]: {
        //     // left: "84%",            
        // },        
        
        // width: "62px",
        // whiteSpace: "nowrap",
        // fontSize: "0.8rem",
        
        // padding: theme.spacing(1* 0.2, 1),
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: gray[300],
        // borderRadius: "16px 0 0 16px", 
        // textAlign: "center",
        zIndex: 1,
    },
    priceComment: {
        position: "absolute",
        top: 0,
        height: 22,
    },    

    burst: {
        width: 60,
        padding: theme.spacing(1),
        position: "absolute",
        top: 45,
        right: 35,  
    },
    specialcomment: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 10,
        left: 5,
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#fff"
    },
    select: {
        borderRadius: "24px",
        lineHeight: 24,
    },
    // actcover:{
    //     height: 308,
    //   },

    cardcontent: {        
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    
    name: {        
        fontWeight: 400,
        fontSize: "1.1rem",
        // marginBottom: theme.spacing(1),
        height: 60,         
        textOverflow: "clip",        
        overflow: "hidden",        

        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            height: 80,
          },        
    },
    actions: {
        // position: "absolute",
        // left: 0,
        // right: 0,
        bottom: 0,
      },
    action4:{
        backgroundColor: theme.palette.secondary.main,
    },
    
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
    },
    buy: {
        padding: theme.spacing(4),
        // marginLeft: "auto",            
        // margin: theme.spacing(1),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    redprice: {       
        fontWeight: 300,
        marginRight: theme.spacing(1),
    },
    priceOld: {
        fontWeight: 300,
        color: gray[400]
        // textDecoration: "line-through",        
        // color: "#8a8a8a",        
    },
    
    specialtag: {        
        position: "relative",
        left: "34.666667%",
        marginRight: theme.spacing(1),      
        // marginTop: theme.spacing(-1 *1 ),
        // width: "41.666667%",       
        
        // textAlign: "center",
        zIndex: 2,
    },

    btnright: {
        textAlign: "right",
    },
    blabel: {        
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },    
    price: {        
        marginRight: theme.spacing(2),
    },
    colorRed: {
        color: red[500],
        fontSize: "1.7rem"
    },
    colorBlue: {
        color: blue[500],
    }, 
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(1),        
    },
    adv:{
        height: 150,
        border: "solid 1px red"
    },
    blockquote: {        
        // lineHeight: "1rem",
        fontSize: "1rem",
        fontStyle: "italic",
        margin: "0px auto",
        position: "relative",
        // fontFamily: "Georgia, serif",
        padding: theme.spacing(3, 3),
        borderRadius: theme.spacing(2),
        // backgroundColor: theme.palette.appbarsecond.background,
        backgroundColor: theme.palette.type === "light" ? alpha(theme.palette.common.black, 0.04) : alpha(theme.palette.common.white, 0.06),
        // backgroundColor: "rgb(244, 246, 248)",
        color: theme.palette.type === "light" ? "rgb(99, 115, 129) !important": "rgb(77, 127, 171) !important",
        "&::before": {
            fontFamily: "Georgia, serif",
            left: theme.spacing(2),
            top: 0,
            content: "\"“\"",
            fontSize: "2em",
            display: "block",            
            position: "absolute",
            color: "rgb(145, 158, 171)"
        },
        "&::after": {
            fontFamily: "Georgia, serif",
            // left: theme.spacing(2),
            bottom: theme.spacing(-2),
            right: theme.spacing(2),
            content: "\"”\"",
            fontSize: "2em",
            display: "block",            
            position: "absolute",
            color: "rgb(145, 158, 171)"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(3),
        }
      },
      blockquote_text: {
        fontSize: "1.2rem",
      },
      tabprod: {
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(3),
        }
      },
      btnsreview: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(3),
        } 
      },
      reviewDate: {
          textAlign: "right",
          paddingTop: 20,
          fontSize: ".7rem"
      },            
      containerScroll: {
        display: "flex",
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: "none"
        },
    },
    cardScroll: {
        width: "47vh",
        marginRight: theme.spacing(2),
    },
    shareButton: {
        marginRight: theme.spacing(1)
    },
    pdfButton: {    
    },          
    sup: {
        fontSize: '.6em',
        marginRight: theme.spacing(1),
    },   

    catalogItem: {
        height: "522px",
        borderWidth: 0,
        borderRadius: 12,
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            height: "390px",
        }, 
        "&:hover": {
            zIndex: 1000,
        },
    },
    wrapper: {        
        position: "absolute",        
        zIndex: 1,
        width: "100%",        
        height: "516px",
        overflow: "hidden",
        left: 0,
        top: 0,        
        borderRadius: 12, 
        [theme.breakpoints.down("sm")]: {
            height: "387px",
            minHeight: "387px",
        }, 
        "&:hover": {
            height: "auto !important",
            transform: "scale(1.04)",
            borderWidth: 0,
            boxShadow: theme.palette.ambilight.boxshadow
        }
    },
    rbox: {
        height: "100%",
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1)
        },
    }
});

class Review {
    constructor(Id, Rating, Advantage, Limitations, Text, UserName, UserEmail, ManagerChangeState, 
            ProductCode, ProductName,
            Useful, Reviewtype, Reviewstate) {
        this.Id = Id;
        this.Rating = Rating;
        this.Advantage = Advantage;
        this.Limitations = Limitations;
        this.Text = Text;
        this.UserName = UserName;
        this.UserEmail = UserEmail;
        this.ManagerChangeState = ManagerChangeState;
        this.ProductCode = ProductCode;
        this.ProductName = ProductName;
        this.Useful = Useful;
        this.Reviewtype = Reviewtype;
        this.Reviewstate = Reviewstate;        
        // this.CreatedOn = CreatedOn;
        // this.UpdatedOn = UpdatedOn;
    }
}

function getCleanHtml(html){
    return html.replace(/(<? *script)/gi, "illegalscript");
}

function getUserName(regNumber, userName){
    if(regNumber.length > 0){
        const words = userName.split(" ");
                
        let str = "";
        let count = 0;
        if(words.length > 0){
            for (let i = 0; i < words.length; i++) {                
                const word = words[i];
                if(word.length === 0)
                    continue;
                
                if(word.length > 1){        
                    if(count === 0){
                        str =`${word[0].charAt(0)}.`;
                    } else {
                        str = word +" "+ str;
                    }        
                    count++;                
                }
                if( count>1 )
                    break;
            }
        }
        return str.toLowerCase();
        // return (`${words[1]} ${words[0].charAt(0)}.`).toLowerCase();
    }

    return userName.toLowerCase();
}

function getRating(number){
    let rating = Number("0." + String(number).split(".")[1]);    
    let _num = parseInt(number) + (rating > 0 ? 0.5 : 0);
    return _num;
}

function getUserAvatar(regNumber, userName){
    if(regNumber.length > 0){
        const words = userName.split(" ");
        return `${words[1].charAt(0)}${words[0].charAt(0)}`;
    }
    return "U";
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && <div style={{paddingTop: 15, minHeight: 200}}>{children}</div>}
      </Typography>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    labelL: PropTypes.string,
    value: PropTypes.number.isRequired,
    labelR: PropTypes.string,

};

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center" mb={1}>
        <Box minWidth={55}>
          <Typography variant="body2" color="textSecondary">{props.labelL}</Typography>
        </Box>

        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={props.value}/>
        </Box>
        
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{props.labelR}</Typography>
        </Box>

      </Box>
    );
}

class CatalogItemLg extends React.Component {  
    constructor(props) {
        super(props);         
        
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            openDlg: false,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            qty: 1,            
            expanded: false,            
            selChildId: undefined,
            child: undefined,            
            tabVal: 0,
            tabReviewTab: 0,
            code: undefined,            
            showAddRating: false,            
            
            reviews: undefined, 
            page: 1,            
            totalCount: 0,
            pageSize: 5,

            ratingVal: 0,
            advantage: "",
            limitations: "",
            reviewText: "",
            reviewUserName: "",
            reviewUserEmail: "",
            rating: {
                average: 1.8,
                from: 5,
                total: 75.24,
                star5: 69.21,
                star4: 39.81,
                star3: 42.81,
                star2: 66.88,
                star1: 1.62,
            },
            productsBySeries: [],
            related:[]
        };
        // eslint-disable-next-line no-undef
        this.cdn = `${process.env.REACT_APP_CDN}Pictures/`;
        this.imgXsSize = "76x76";
        this.myRef = React.createRef();
        // this.authService = new AuthService();
        moment.locale("uk");
    }
    
    static contextType = AppContext;
    
    loadReviews = (code, page, pageSize) => {
        const url = `/api/v1.0/review/${code}/${page}/${pageSize}`;
        // console.log(URL);         
        fetch(url)
            .then(response => response.json())
                .then(data => {                    
                    // console.log(data);
                    this.setState({ reviews: data });
                })
            .catch(error => console.log(error));
    };

    loadOwnerSerias = (code, country, lang) => {        
        const url = `/api/v1/CatalogItem/ByOwnerSeria/${code}/${country}/${lang}`;        
        fetch(url)
            .then(response => response.json())
                .then(data => {                    
                    // console.log('productsBySeries', data);
                    this.setState({ productsBySeries: data });
                })
            .catch(error => console.log(error));
    };

    loadRelated = (code) => {
        const context = this.context;        
        const url = `/api/v1/catalogitem/related/${code}/${context.country}/${context.lang}`;
        
        fetch(url)
            .then(response => response.json())
                .then(data => {
                    // console.log('related', data);
                    this.setState({ related: data });
                })
            .catch(error => console.log(error));
    }

    componentDidMount() {        
        const {page, pageSize } = this.state;  
        const { item, country, lang } = this.props;
        const { getUser } = this.context;
        getUser().then( (user) => {  
            this.setState({ user: user, 
                            reviewUserEmail: user.profile.email, 
                            reviewUserName: user.profile.name 
                        });
        });

        const code = item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code;           
        this.loadReviews(code, page, pageSize);
        this.loadOwnerSerias(code, country, lang);
        this.loadRelated(code);
    }

    // static getDerivedStateFromProps(props, state) {
    //     const { item } = props;
        
    //     console.log("getDerivedStateFromProps", item);
        
    //     return item;
    // }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState)  {
        const {page, pageSize } = this.state;  
        const { item, country, lang } = this.props;                
        if(item.Code !== prevProps.item.Code || country !== prevProps.country || lang !== prevProps.lang ){            
            this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
            this.loadOwnerSerias(
                item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code,
                country, 
                lang
            );
            this.loadRelated(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code);
        }
    }

    handleSetTabVal = (event, value) => {
        this.setState({ tabVal: value});        
    };

    handleSetTabReviewVal = (event, value) => {
        this.setState({ tabReviewTab: value});        
    };

    handleShowRating = () => {
        const { showAddRating } = this.state;
        this.setState({showAddRating : !showAddRating});
        
        this.myRef.current.scrollIntoView();                
    };

    handleCancelRaview = () => {
        this.setState({showAddRating : false});
    };

    validateReview = () => {
        const { reviewText, reviewUserName, reviewUserEmail} = this.state;
        let errorReviewText = false;
        let errorTxtReviewText = "";

        let errorReviewUserName = false;
        let errorTxtReviewUserName = "";

        let errorReviewUserEmail = false; 
        let errorTxtReviewUserEmail = "";
        let valid = true;
        
        if(reviewText.length === 0){
            valid = false;        
            errorReviewText = true;
            errorTxtReviewText = "Fill review";
        }

        if(reviewUserName.length === 0 ){
            valid = false;        
            errorReviewUserName = true;
            errorTxtReviewUserName = "Fill user name";
        }

        if(reviewUserEmail.length === 0 ){
            valid = false;        
            errorReviewUserEmail = true; 
            errorTxtReviewUserEmail = "Fill email";
        }else{
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(reviewUserEmail)) {
                valid = false;        
                errorReviewUserEmail = "Not valid pattern email address"; 
                errorTxtReviewUserEmail = "Fill valid email address";
            }
        }

        this.setState({
            errorReviewText: errorReviewText, 
            errorTxtReviewText: errorTxtReviewText,
            errorReviewUserName: errorReviewUserName,
            errorTxtReviewUserName: errorTxtReviewUserName,
            errorReviewUserEmail: errorReviewUserEmail,
            errorTxtReviewUserEmail: errorTxtReviewUserEmail
        });

        return valid;
    };

    handleSaveRaview = async () => {
        if(!this.validateReview()){
            return;
        }
        const {item} = this.props;
        const { ratingVal, advantage, limitations, user, 
                reviewText, reviewUserName, reviewUserEmail} = this.state;
        
        // const child = this.getChild( code ? code:item.Code);
        const article = item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code; // child ? child.Code : item.Code;
        
        const productName = item.GroupProductId !== EGUID ? item.LocalName : item.GroupProductName; // child ? child.LocalName : item.LocalName;
        const review = new Review( 0, ratingVal, advantage, limitations, reviewText, reviewUserName, reviewUserEmail, "", 
                                   article, productName, true, 0, 0 );
        
        console.log(JSON.stringify(review));
        const url = "/api/v1.0/Review";
        
        const authorization = user ? `${user.token_type} ${user.access_token}`: ""; 
        // headers: {"Authorization": `${user.token_type} ${user.access_token}` }

        await fetch(url, {
            method: ("POST"),
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": authorization
            },
            body: JSON.stringify(review)
          })
            .then((response) => response.json())
            .then((data) => {
                this.setState({showAddRating : false});
                console.log(data);
            })
            .catch((error) => {
              console.log("Error", error);
            });        
        
    };

    handleSetRatingVal = (event, value) => {
        this.setState({ ratingVal: value});      
    };

    // eslint-disable-next-line no-unused-vars
    handleChangePage = (event, newPage) => {
        console.log("newPage" ,newPage);

        const { item } = this.props;
        const { pageSize } = this.state;
        let page = newPage + 1;

        this.setState({page: page });

        this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
        
        // throw new Error("handleChangePage: Not implementation");
    };

    handleChangeRowsPerPage = (event) => {        
        const { item } = this.props;
        let page = 1;
        let pageSize = event.target.value;        
        this.setState({page: page, pageSize: pageSize});
        this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleQtyChange = (event) => {
        if(event.target.name === "qty"){          
          this.setState({ [event.target.name]:  parseInt(event.target.value) === 0 ? 1 :  event.target.value });
        }      
    }
    
    AvailableCSS = (value) =>{
        const { classes} = this.props;
        if(value){
            return "";
        }
        else{
           return classes.grayscale;
        }
    }

    handleAddToOrder = (e, pos) => {
        const { allowOrder, t} = this.props;
        const {user} = this.state;

        
            if( allowOrder || user){ 
                if(pos.FreeRest <= 0){
                    window.location.href = "https://direct.smartsender.com/redirect?continue=viber%3A%2F%2Fpa%3Fcontext%3DZGw6MjQ4OTg%26chatURI%3Djerelia_bot";
                    return;
                }
        
                const {disabled} = this.props;
                const { item } = this.props;
                const { qty, code } = this.state;
                const child = this.getChild( code ? code:item.Code);
                
                if(disabled === true)
                    return;
        
                if (item.Children.length > 0 && child){
                    // console.log("Add child to order:", child);
                    // this.setState({ ProductId: child.ProductId });         
                    this.props.actions.addToOrder([ { pos: child, qty: qty, orderrowtype: 0}] )
                } else {
                    // console.log("Add master to order:", pos);
                    // this.setState({ProductId: pos.ProductId});         
                    this.props.actions.addToOrder([ { pos: pos, qty: qty, orderrowtype: 0}] )
                }    
            } else {
                this.props.actions.showMessage(t("order.AllowAddToOrderText"), "warning");
                // this.setState({openDlg: true});
            }               
    }

    handleChangeChild = (event) => {
        const { item } = this.props;
        let child;
        if(item.Children.length > 0){
            child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });            
            this.setState({ code: child ? child.Code : undefined });
        }
    };

    getChild = (code) => {
        const { item } = this.props;
        let child = undefined;
        
        if(item.Children.length > 0){
            child = item.Children.find( (el ) => {
                if(el.Code === code)
                    return el;
                return null;
                });                        
        }        
        return child;
    };

    SpecialPriceLabel = (value) => {
        const { classes } = this.props;        
        switch (value) {
            case 0:
                return  <div className={classes.specialprice} title="Wow ціна" style={{backgroundColor: pink[500] }} aria-label="recipe"><NewReleasesIcon /></div>  // "Wow ціна";
            case 1:
                return <div className={classes.specialprice}  title="Акція" style={{backgroundColor: blue[500] }} aria-label="recipe"><BrightnessAutoIcon /></div>  //"Акція";    
            default:
                return null;    
        }
    }
    
    handleLinkClick =(event, url) => {
        event.preventDefault();
        this.props.history.push(url);
        // console.info("You clicked a breadcrumb.");
    }

    handleCloseDlg = () => {
        this.setState({openDlg: false});
    };

    handleCopyUrl = () => {
        const {item, userName } = this.props;
        const {code } = this.state;
        const child = this.getChild( code ? code:item.Code);

        const _url = `${window.location.protocol}//${window.location.host}/product/${(child ? child.Code : item.Code)}?ref=${userName}`;
        // console.log(url);        
        navigator.clipboard.writeText(_url);
        // this.setState({ showSnack: true, url: _url});
    };

    handleGoToPage = () => {
        const {item } = this.props;
        console.log('MAGAZINT', item.Magazine);
        const {code } = this.state;
        const child = this.getChild( code ? code:item.Code);
        this.props.history.push(`/interactivecatalog?url=${item.Magazine}&p=${(child ? child.MagazinePageIndex : item.MagazinePageIndex)}`);
    };

    handleGoRegister = () => {
        this.props.history.push('/register');
    };

    showPrice = () => {
        
        const {code, item, discounts, classes} = this.props;

        const child = this.getChild( code ? code:item.Code);
        const price = child ? child.CatalogPrice: item.CatalogPrice; // child.SellingPrice : item.SellingPrice;
        const itemPrice = child ? child.Price : item.Price;
        const priceBB = child ? child.CatalogPricePv : item.CatalogPricePv; 
        const disc = discounts.find(d => d.Amount >= priceBB );
        
        let showPrice = price;        
        // if(disc){
        //     showPrice = parseFloat(parseFloat(itemPrice) * (1 -  parseFloat(disc.Percent) / 100)).toFixed(2);
        // }  
        
        
        return (
            <Typography  color="primary" variant="h3" component="span" style={{marginRight: 8, fontWeight: 100}}>
                {parseInt(showPrice)}
                <sup className={classes.sup}>
                    .{(Math.round(100 * showPrice ) / 100).toFixed(2).split('.')[1]}
                </sup>                                        
                <small style={{fontSize: ".5em"}}>
                    {item.CurrencySign}
                </small>                            
            </Typography>
        );
    }


    render(){
        const { item, classes, width, IsAuthenticated, discounts, disabled, t, userName } = this.props;        
        const { user, productsBySeries, related, code, page, pageSize, reviews, tabVal,tabReviewTab, showAddRating, 
                ratingVal, advantage, limitations, reviewText, reviewUserName, reviewUserEmail, 
                errorReviewText, errorTxtReviewText, errorReviewUserName, errorTxtReviewUserName, 
                errorReviewUserEmail, errorTxtReviewUserEmail} = this.state;
                
        const child = this.getChild( code ? code:item.Code);            
        return(
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={0} style={{marginBottom: 8}}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                        <Link color="inherit" href="/" onClick={this.handleLinkClick} className={classes.link}>
                            <HomeOutlinedIcon className={classes.icon} />                    
                        </Link>
                        <Link
                            color="inherit"
                            href={`/catalog/${ child ? child.Series : item.Series}`}
                            onClick={e => this.handleLinkClick(e,`/catalog/${ child ? child.Series : item.Series}`)}
                            className={classes.link}
                        >
                            { child ? child.Series : item.Series}
                        </Link>                    
                    </Breadcrumbs>                    
                </Paper>
                
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1} > 
                    {/* Left column */}                            
                    <Grid item xs={12} sm={6} md={5}>
                        <Paper className={classes.paper} elevation={0} style={{height: "100%"}}>
                        {
                            width === "xs" && 
                            <Typography  className={classes.header} component="h1" variant="h4" align="center" style={{marginTop: 16}} gutterBottom>
                                {child ? child.LocalName : item.LocalName}
                            </Typography>
                        }



                        { IsAuthenticated && 
                            <div style={{position:'relative'}}>
                                { child &&  child.CatalogPricePv && child.CatalogPricePv && child.CatalogPricePv > 0.01 && 
                                <Chip 
                                    className={classes.priceBB} 
                                    variant="outlined" 
                                    size="small"
                                    color="primary"
                                    label={parseFloat(child.CatalogPricePv).toFixed(1)}
                                    avatar={<Avatar>{t('BB')}</Avatar>}
                                />
                                }
                                {item && item.CatalogPricePv > 0.01 && 
                                    <Chip 
                                        className={classes.priceBB} 
                                        variant="outlined" 
                                        size="small" 
                                        color="primary"
                                        label={parseFloat(item.CatalogPricePv).toFixed(1)}
                                        avatar={<Avatar>{t('BB')}</Avatar>}
                                    />
                                }                        
                            </div>                                   
                        }

                        
                        <div style={{position:'relative'}}>
                            { child && child.Comment.length > 0 && 
                                <Chip 
                                    size="small"
                                    variant="outlined"
                                    className={classes.priceComment}
                                    color="secondary"
                                    label={<Typography variant="body2">{child.Comment}</Typography> } 
                                />
                            }
                            { item && item.Comment.length > 0 && 
                                    <Chip size="small"
                                        variant="outlined"
                                        className={classes.priceComment}
                                        color="secondary"
                                        label={<Typography variant="body2">{item.Comment}</Typography> } 
                                />
                            }
                        
                            { 
                                child && child.Tag.length > 0 &&
                                    
                                        <Chip size="small" 
                                            color="secondary"
                                            className={classes.specialtag}
                                            label={child.Tag}                                                    
                                            avatar={<Avatar><NotificationsActiveOutlinedIcon style={{fontSize: 16}} /> </Avatar>}
                                        />
                                    
                            }

                            
                            {
                                item && item.Tag.length > 0 &&                                
                                    <Chip size="small" color="secondary"
                                        label={item.Tag}
                                        className={classes.specialtag}                              
                                        avatar={<Avatar><NotificationsActiveOutlinedIcon style={{fontSize: 16}} /> </Avatar>}
                                    />                               
                            }
                        </div>
                                                        
                        
                        <ProductSlider available={ child ? child.Available : item.Available}  items={ child ? child.Photos : item.Photos}></ProductSlider>
                        

                        {width === "xs" && child && item.Children.length > 0 &&
                        <Grid container direction="row" justifyContent="center" alignItems="center" >
                            <Grid item xs={12} sm={10} md={8} style={{height: "64px"}}>                                                                        
                                <TextField 
                                    select
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    value={child.ProductId}
                                    variant="outlined"
                                    onChange={this.handleChangeChild}
                                    InputProps={{
                                        classes: { notchedOutline: classes.select },
                                    }}
                                    >
                                    {item.Children.map((option) => (
                                    <MenuItem key={option.Code} value={option.ProductId}>
                                        <ListItemIcon>                                
                                            <Brightness1Icon fontSize="small" style={{color: (option.Color.length> 0 ? `#${option.Color}`: "#fff")}} />
                                        </ListItemIcon>
                                        {option.CharName}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            
                            </Grid>
                        </Grid>
                        }
                        </Paper>
                    </Grid>
                    
                    {/* Right column */}
                    <Grid item xs={12} sm={6} md={7}>
                        <Paper className={clsx(classes.paper, classes.rbox)} elevation={0} >
                        {
                            width !== "xs" &&                                
                            <Typography  className={classes.header} component="h1" variant="h4" gutterBottom>
                                {child ? child.LocalName : item.LocalName}
                            </Typography>
                        }
                        
                        {/* Price row */}
                        <Grid className={classes.priceblock} container direction="row" alignItems="flex-start" >
                            <Grid item xs={12} >  
                            { IsAuthenticated ?
                            <>
                                <Typography component="div" display="block" align={width === "xs" ? "center":"left" }>
                                                                        
                                    {this.showPrice()}
                                    
                                    <Typography className={classes.priceOld} variant="h6" component="span">                                                    
                                            <span className="price--line-through">
                                                { parseInt(item.OldPrice === 0 ? item.Price: item.OldPrice)}
                                                <sup className={classes.sup}>
                                                .{(Math.round(100 * (item.OldPrice === 0 ? item.Price: item.OldPrice)) / 100).toFixed(2).split('.')[1]}
                                            </sup>
                                            </span> 
                                            <small style={{fontSize: ".6em"}}>{item.CurrencySign}</small>                                        
                                    </Typography> 
                                                                                
                                    {/* {item.UseLO && } */}
                                    { item.CatalogPricePv !== 0.01 && 
                                    <Box p={1} >
                                        <Typography gutterBottom>
                                            {t("product.Price if order amount")}:
                                        </Typography>
                                        {discounts.map((d, idx) => (
                                        
                                            <Chip 
                                                key={idx} 
                                                variant="outlined" 
                                                size="small" 
                                                style={{marginRight: 8, marginBottom: 4}}                                                
                                                label ={ idx < discounts.length - 1 ? 
                                                    `${t("product.Price up to", {value: d.Amount, price: parseFloat(parseFloat(item.Price) * (1 -  parseFloat(d.Percent) / 100)).toFixed(2)})} ${item.CurrencySign? item.CurrencySign : ""}` : 
                                                    `${t("product.Price from", {value: discounts[idx-1].Amount, price: parseFloat(parseFloat(item.Price) * (1 -  parseFloat(d.Percent) / 100)).toFixed(2)})} ${item.CurrencySign? item.CurrencySign : ""}`
                                                    }
                                            />
                                        ))}
                                    </Box>     
                                }                                                                                   
                                </Typography>                             
                            </>
                            :        
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                
                                >
                                    <Grid item >
                                        <Typography color="primary" component="span" style={{fontSize: "2.2em", marginRight: 8}}>
                                            {parseFloat(child ? child.Price : item.Price).toFixed(2)} <small style={{fontSize: ".7em"}}>{item.CurrencySign}</small>
                                        </Typography>                                        
                                        { item.OldPrice > 0 &&                                        
                                            <Typography component="span" className="price--line-through text-gray"  >
                                                {parseFloat(child ? child.OldPrice : item.OldPrice).toFixed(2)} <small style={{fontSize: ".7em"}}>{item.CurrencySign}</small>
                                            </Typography>
                                        }
                                </Grid>
                                <Grid item>
                                    {discounts && discounts.length > 0 &&                                 
                                    <Chip 
                                        variant='default'                                         
                                        color="primary"
                                        icon={<PersonAddOutlinedIcon />}
                                        label = {t("product.Discount after registration", {percent: discounts[discounts.length - 1].Percent })}
                                        onClick={this.handleGoRegister}
                                    >
                                    </Chip>                                                                    
                                    }
                                </Grid>   
                            </Grid>
                            }                                    
                            </Grid>                                    
                        </Grid>
                        
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4} >
                            <Grid item xs={12} sm={6} >
                            {width !== "xs" && child && item.Children.length > 0 &&
                                <TextField 
                                    select
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    value={child.ProductId}
                                    variant="outlined"
                                    onChange={this.handleChangeChild}
                                    InputProps={{
                                        classes: { notchedOutline: classes.select },
                                    }}
                                    >
                                    {item.Children.map((option) => (
                                    <MenuItem key={option.Code} value={option.ProductId}>
                                        { option.Color.length > 0 &&
                                            <ListItemIcon>
                                            {/* <img width={24} height={24} 
                                            src = {`${this.cdn}properties/${option.MiniFile}`}
                                            /> */}                                                    
                                                <Brightness1Icon fontSize="small" style={{color: (option.Color.length > 0 ? `#${option.Color}`: "#fff")}} />                                                                                                            
                                            </ListItemIcon>
                                        }
                                        { option.CharName.length > 0 ? option.CharName : <span>&nbsp;</span>}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            }
                            </Grid>
                        </Grid>                                                                
                        
                        <Grid container direction="row" spacing={2} alignItems="center" >                                    
                        { width !== "xs" &&
                            <Grid item >
                                { user &&
                                <Chip  style={{width: 140}} 
                                    icon={item.FreeRest <= 0 ? <AccessTime /> : 
                                    (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon />)}
                                    // label={ t("buttons.AddToCart") }
                                    label= {item.FreeRest <= 0 ? t("buttons.NotifyArrival") : t("buttons.Buy")}
                                    clickable
                                    // disabled={item.FreeRest <= 0 }
                                    variant= { item.FreeRest <= 0 ? "outlined" : "default"}                            
                                    color={ item.FreeRest <= 0 ? "secondary" : "primary"}
                                    onClick={(e) => this.handleAddToOrder(e, item)}
                                />
                                }
                            </Grid>
                        }
                            <Grid  item >
                                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                    <Grid item >
                                        <Rating readOnly name="half-rating" value={getRating(item.ProductEvaluation.AvgRating)} precision={0.5} />                                                                                                
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {t("rev", {qty: item.ProductEvaluation.Qty})}
                                        </Typography>                         
                                    </Grid>
                                </Grid>                                    
                            </Grid>

                            <Grid item>
                            
                                {userName && 
                                    <IconButton color="primary" className={classes.shareButton} onClick={()=> this.handleCopyUrl()}>
                                        <ShareIcon />
                                    </IconButton>
                                }

                                {child && child.MagazinePageIndex > 0 && 
                                    <IconButton color="primary" className={classes.pdfButton} onClick={()=>this.handleGoToPage()} >
                                        <PictureAsPdfOutlinedIcon />
                                    </IconButton>
                                }

                                {item && item.MagazinePageIndex > 0 && 
                                    <IconButton color="primary" className={classes.pdfButton} onClick={()=>this.handleGoToPage()}>
                                        <PictureAsPdfOutlinedIcon />
                                    </IconButton>
                                }                                
                            </Grid>

                            
                            <Grid item xs={12}>
                                <table className={classes.tableAttr}>
                                    <tbody>
                                        <tr className={classes.attr}>
                                            <td><span>{t("product.Article")}</span></td>
                                            <td className={classes.tdAttr} >{ child ? child.Code : item.Code }</td>

                                            <td><span>{t("product.Seria")}</span></td>
                                            <td className={classes.tdAttr}>{ child ? child.Series : item.Series}</td>
                                        </tr>
                                        <tr className={classes.attr}>
                                            <td><span>{t("product.VolumeSubstances")}</span></td>
                                            <td className={classes.tdAttr} style={{whiteSpace: "nowrap"}}>{ child ? child.VolumeSubstances : item.VolumeSubstances} <small>{ child ? child.Measure : item.Measure}</small> </td>

                                            <td><span>{t("Weith")}</span></td>
                                            <td className={classes.tdAttr} style={{whiteSpace: "nowrap"}}>{ 1000 * (child ? child.Weith : item.Weith)} <small>{t("gram")}</small> </td>
                                        </tr> 
                                        <tr className={classes.attr}>
                                            <td colSpan={4}>
                                                <strong>
                                                    {t("product.Ingredients")} 
                                                </strong>
                                                <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.SubstanceComposition : item.SubstanceComposition) ) }}></div>
                                            </td>
                                        </tr>

                                        {/* <tr className={classes.attr}>
                                            <td>{t("product.Ingredients")}:</td>
                                            <td className={classes.tdAttr} style={{whiteSpace: "nowrap"}} colSpan={3}>
                                                <div className={classes.SubstanceComposition}>                                                
                                                    <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.SubstanceComposition : item.SubstanceComposition) ) }}></div>
                                                </div>
                                            </td>       
                                        </tr> */}
                                    </tbody>
                                </table>
                                
                            </Grid>                                    
                            
                            {
                            item.BestReview && 
                                <Grid item xs={12}>
                                <blockquote className={classes.blockquote}>
                                    <Typography className={classes.blockquote_text} gutterBottom>
                                        {item.BestReview.Text}
                                    </Typography>
                                    <Typography align="right" style={{textTransform: "capitalize"}} variant="caption" display="block" gutterBottom>
                                        { getUserName(item.BestReview.RegNumber, item.BestReview.UserName) }
                                    </Typography>
                                </blockquote>
                            </Grid>
                            }

                        </Grid>
                        </Paper>
                    </Grid>                        
                </Grid>

                <Paper className={classes.paper} elevation={0} style={{marginTop: 16, marginBottom: 8}}>                    
                    <Tabs 
                        className={classes.tabprod}                         
                        centered
                        // variant="scrollable"
                        // scrollButtons="auto"
                        value={tabVal}
                        onChange={this.handleSetTabVal}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={t("product.About")} />
                        <Tab label={t("product.HowUse")} />
                        {/* <Tab label={t("product.Ingredients")} /> */}
                        {/* <Tab style={{width: "48px", minWidth: "48px"}} icon={<ShareIcon />}  /> */}
                        
                    </Tabs>

                <TabPanel value={tabVal} index={0}>
                    <div className={classes.SubstanceComposition}>
                        <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.FullDescription : item.FullDescription) ) }}></div>
                    </div>
                </TabPanel>

                <TabPanel value={tabVal} index={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="div" className={classes.SubstanceComposition}>
                                <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.UseSubstance : item.UseSubstance) ) }}></div>
                            </Typography>
                        </Grid>
                    </Grid>
                </TabPanel>

                {/* <TabPanel value={tabVal} index={2}>
                    <div className={classes.SubstanceComposition}>
                        <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.SubstanceComposition : item.SubstanceComposition) ) }}></div>
                    </div>
                </TabPanel> */}

                {/* <TabPanel value={tabVal} index={3}>
                    Social 
                </TabPanel> */}
                </Paper>

                { item.ExternalLinks && item.ExternalLinks.length > 0 && 
                <Paper className={classes.paper} elevation={0} style={{marginTop: 8, marginBottom: 8, paddingTop: 16, paddingBottom: 16}}>
                    <Grid container direction="row" justifyContent="center" alignItems="stretch" > 
                        {item.ExternalLinks.map((external) => (
                        <Grid key={external.LineNumber} item xs={12} sm={6} >
                            <Youtube youtubeId={external.LinkToProduct} />
                        </Grid>            
                        ))}
                    </Grid>
                </Paper>
                }

                {/* Same Serias */}
                { productsBySeries.length > 0 &&
                    <>
                        <Paper className={classes.paper} elevation={0} style={{marginBottom: 8}} >
                            <Typography align="center" display="block" variant="h5">{t("product.TheSameSeria", {name: child ? child.Series : item.Series})}</Typography>
                        </Paper>

                        <ItemsStepper items={productsBySeries} width={width} IsAuthenticated={ IsAuthenticated } userName={userName}/>
                    </>
                }

                {/* Reviews */}
                <Paper className={classes.paper} elevation={0} style={{padding: 16, marginTop: 16}} >
                    <Grid container direction="row" justifyContent="flex-start" spacing={2} >
                        <Grid item  >
                            <Grid container direction="row" justifyContent="flex-start" >
                                <Grid item>
                                    <Tabs value={tabReviewTab}  onChange={this.handleSetTabReviewVal} indicatorColor="primary" textColor="primary" >
                                        {/* <Tab className={classes.tabReview} label={ `${t("product.Reviews")} (${reviews})`} />
                                        <Tab className={classes.tabReview} label={ `${t("product.Questions")} (${reviews})`} /> */}
                                        <Tab className={classes.tabReview} label={t("product.Reviews")} />
                                        {/* <Tab disabled className={classes.tabReview} label={t("product.Questions")} /> */}
                                    </Tabs>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item >
                                            <Typography variant="h4" className={classes.averageReview}>
                                                { parseFloat(item.ProductEvaluation.AvgRating).toFixed(1) }                                                    
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Rating 
                                                style={{fontSize: "11px"}} 
                                                readOnly 
                                                name="half-rating" 
                                                size="small" 
                                                value={getRating(item.ProductEvaluation.AvgRating)} 
                                                precision={0.5} 
                                            />
                                            <Typography style={{fontSize: "11px"}}>
                                            {t("rev", {qty: item.ProductEvaluation.Qty})}
                                            </Typography>
                                        </Grid>                                                                                
                                    </Grid>
                                </Grid>
                            </Grid>                                
                        </Grid>

                        <Grid item >
                            <Typography className={classes.btnsreview} component="div" align="right">        
                                <Chip className={classes.chipBtn}
                                    icon={<PostAddIcon />}
                                    label={t("product.WriteReview")}
                                    clickable
                                    color="primary"
                                    variant="outlined"
                                    onClick={this.handleShowRating}
                                />
                            </Typography>
                        </Grid>                            
                    </Grid>
                    
                    <TabPanel value={tabReviewTab} index={0}>
                        {reviews && 
                        <div>
                            {reviews.Data.map((review) => (
                                <div style={{marginBottom: "16px"}} key={review.Id}>
                                    <Grid container direction="row">
                                        <Grid item xs={9} sm={10}>

                                            <Grid container direction="row" spacing={2}>
                                                <Grid item>
                                                    <Avatar>{getUserAvatar(review.RegNumber, review.UserName)}</Avatar>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.reviewAuth}>
                                                        <Typography variant="caption" component="div"  color="textSecondary">                                                        
                                                                {getUserName(review.RegNumber, review.UserName)}
                                                        </Typography>
                                                        <Rating style={{fontSize: "14px"}} readOnly name="half-rating" size="small" value={review.Rating} precision={0.5} />                                                            
                                                    </div>
                                                </Grid>   
                                                <Grid item>
                                                    <Typography className={classes.reviewDate} color="textSecondary" gutterBottom>
                                                    { moment(review.CreatedOn).format("DD-MM-YY") }
                                                    </Typography>
                                                </Grid> 

                                            </Grid>                                                                                                                            
                                        </Grid>

                                        
                                    </Grid>
                                    
                                    <div style={{paddingTop: "16px"}} >
                                    {
                                        review.Advantage.length > 0 &&
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <b>{t("reviews.Advantage")}</b>: {review.Advantage}
                                        </Typography>
                                    }

                                    {
                                        review.Limitations.length > 0 &&
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <b>{t("reviews.Limitations")}</b>: {review.Limitations}
                                        </Typography>
                                    }

                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {/* <b>{t("reviews.Comment")}</b>:  */}
                                            {review.Text}
                                        </Typography>
                                    </div>
                                </div>
                            ))}

                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 25]}
                                labelRowsPerPage={""}
                                count={reviews.TotalCount}
                                page={page-1}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />

                        </div>}

                        
                        <Collapse in={showAddRating} timeout="auto" >                            
                            <Box mb={2} className={clsx(classes.reviewbox, classes.paper)}>
                                <Typography variant="h6" display="block">
                                    {t("reviews.AddReview")}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>{t("reviews.YourReviewAboutProduct")}: {child ? child.LocalName : item.LocalName}</Typography>
                                <Box display="flex" alignItems="center" mb={1}>                                                
                                    <Box width="100%" mr={1}>
                                        <Rating 
                                            precision={1}
                                            name="rating-controlled"
                                            value={ratingVal}
                                            onChange={this.handleSetRatingVal}
                                        />
                                    </Box>                                            
                                </Box>
                                
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            ref={this.myRef}
                                            // inputRef={(input) => {
                                            //     if(input != null) {
                                            //        input.focus();
                                            //     }
                                            //   }}                                                  
                                            fullWidth
                                            id="advantage"
                                            name="advantage"
                                            label={t("reviews.Advantage")}
                                            value={advantage}
                                            multiline
                                            minRows={1}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField                                                        
                                            fullWidth
                                            id="limitations"
                                            name="limitations"
                                            label={t("reviews.Limitations")}
                                            value={limitations}
                                            multiline
                                            minRows={1}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField                                                        
                                            id="reviewText"
                                            name="reviewText"                                                        
                                            required
                                            fullWidth
                                            label={t("reviews.Comment")}
                                            value={reviewText}
                                            multiline
                                            minRows={4}
                                            error={errorReviewText}
                                            helperText={errorTxtReviewText}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            disabled={user ? true: false}
                                            fullWidth
                                            id="reviewUserName"
                                            name="reviewUserName"
                                            label="User name"
                                            value={reviewUserName}
                                            error={errorReviewUserName}
                                            helperText={errorTxtReviewUserName}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            disabled={user ? true : false}
                                            type="email"                                                        
                                            fullWidth
                                            id="reviewUserEmail"
                                            name="reviewUserEmail"                                                        
                                            label="User email"
                                            value={reviewUserEmail}
                                            error={errorReviewUserEmail}
                                            helperText={errorTxtReviewUserEmail}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="div" align="right">
                                            <Button variant="outlined" className={classes.buttons} onClick={this.handleCancelRaview} >{t("buttons.Cancel")}</Button>
                                            <Button variant="contained" color="primary" onClick={this.handleSaveRaview} >{t("buttons.PostReviews")}</Button>
                                        </Typography>
                                    </Grid>                                                
                                </Grid>

                                <Grid  container direction="row" justifyContent="flex-end" alignItems="center" >
                                    <Grid item >
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TabPanel>
                    <TabPanel value={tabReviewTab} index={1}>
                    </TabPanel>
                </Paper>

                {related.length > 0 &&
                <Box pt={2}>
                    <Paper className={classes.paper} elevation={0} style={{ marginBottom: 16}} >
                        <Typography display="block" align= "center" variant="h5">{t("product.Related product")}</Typography>
                    </Paper>
                    
                    {related && related.length > 0 &&
                        <ItemsStepper items={related} width={width} IsAuthenticated={ IsAuthenticated } userName={userName}/>
                    }
                                                
                    {/* <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>                                    
                    { related.map((item) => (
                        <Grid item key={item.Code} xs={6} sm={4} md={3} lg={3}  style={{paddingBottom: 16}}>
                            <div className={classes.catalogItem}>
                                <div className={classes.wrapper}>
                                    <CatalogItemXs item={item} IsAuthenticated={ IsAuthenticated } userName={userName} ></CatalogItemXs> 
                                </div>
                            </div>
                            
                        </Grid>
                    ))}
                    </Grid>                     */}
                </Box>
                } 
            {
                width === "xs" &&              
                <AppBar position="fixed" color='inherit' className={classes.appBarBtn}>
                    
                        <Chip style={{width: '100%'}} 
                            color={ item.FreeRest <= 0 ? "default" : "primary"}
                            icon={item.FreeRest <= 0 ? <AccessTime /> : (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon />)}
                            label={ item.FreeRest <= 0 ? t("buttons.NotifyArrival") : t("buttons.Buy")} 
                            onClick={(e) => this.handleAddToOrder(e, item)}
                        />
                        {/* <Button color={ item.FreeRest <= 0 ? "default" : "primary"} fullWidth
                            onClick={(e) => this.handleAddToOrder(e, item)}
                            variant="contained"                            
                            startIcon={item.FreeRest <= 0 ? <AccessTime /> : (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon />)}
                        >
                            { item.FreeRest <= 0 ? t("buttons.NotifyArrival") : t("buttons.Buy")} 
                        </Button> */}
                    
                </AppBar>
                
                }
            </div>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemLg)
))));
