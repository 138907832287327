import { CustomerState } from "../definitions/customer";

const requesStartType = "START";
const requesMentorsType = "REQUEST_MENTORS";
const receiveMentorsType = "RECEIVE_MENTORS";
const requesMentorType = "REQUEST_MENTOR";
const receiveMentorType = "RECEIVE_MENTOR";
const findMentorType = "FIND_MENTOR";
const setMentorType = "SET_MENTOR";
const requestSMSCodeType = "REQUEST_SMS_CODE";
const receiveSMSCodeType = "RECEIVE_SMS_CODE";

const changeCustomerStateType = "CHANGE_CUSTOMER_STATE";
const changeFieldType = "CHANGE_FIELD";
const changePhoneType = "CHANGE_PHONE";
const clearSelectedMentorType = "CLEAER_SELECTED_MENTOR";
const receiveCountriesType = "RECEIVE_COUNTRIES";

const requesRegisterType = "REQUEST_REGISTER";
const receiveRegisterType = "RECEIVE_REGISTER";

const timeleftRegisterType = "TIMERLEFT_REGISTER";

const searcMentor = async (refId) => {
  const url = `/api/v1.0/adviser/getcustomerbyregistrationcode/${refId}/0`;
  const response = await fetch(url);
  if(response.status !==  200){
    return null;
  }
  const mentor = await response.json();
  return mentor;   
};

const date = new Date();
const initialState = { 
  mentors: [],
  mentor: null,
  finded: null,
  refId: null,
  PromoCode: "",  
  customerState: CustomerState.Consultant,
  isLoading: true,
  regNumber: "",
  FirstName: "",
  LastName: "",
  MiddleName: "",
  Country: "UA",
  Birthday: new Date(date.getFullYear(), date.getMonth()-1, 1),
  Agree: true,
  Password: "",
  PhoneNumber: "",
  Email: "",
  IsMentor: true,
  smsCode: -1,
  newUser: null,
  Role: "Consultant",
  appError: null,
  allowCountries: [],
  maxSmsAttempts: 4,
  timeleft: 0
};



export const actionRegister = {

  clearSelectedMentor: () => async (dispatch) => {
    dispatch({ type: clearSelectedMentorType });
  },

  getSMSCode: () => async (dispatch, getState) => {
    const { PhoneNumber, Email, maxSmsAttempts} = getState().register;
    
    if(!PhoneNumber || !Email ){
        console.log('PhoneNumber || Email undefined');
        return;
    }
    
    if(PhoneNumber === "" || Email === ""){
        console.log('PhoneNumber || Email empty string');
        return;
    }

    dispatch({ type: requestSMSCodeType });
    
    let _timeleft = 60;
    var downloadTimer = setInterval(function(){
      if(_timeleft <= 0){
        clearInterval(downloadTimer);
        dispatch({ type: timeleftRegisterType, timeleft: 0 });    
      } else {    
        dispatch({ type: timeleftRegisterType, timeleft: _timeleft });
      }
      _timeleft -= 1;
    }, 1000);
    
    
    const urlSID = `/api/v1/account/sessionid`;

    fetch(urlSID, { method: "POST" })
    .then((response) => {
      if(response.status !== 200){
        return;
      }

      response.text().then(() => {        
          const url = `/api/v1/account/smscode/${PhoneNumber}`;
            fetch(url, { method: "POST"})
            .then((response) => {
                if(response.status !== 200){ 
                    dispatch({ type: receiveSMSCodeType, smsCode: -1, maxSmsAttempts: maxSmsAttempts-1 });  
                    return;
                }      
                response.json().then((data) => {
                    const smsCode = parseInt(data);
                    dispatch({ type: receiveSMSCodeType, smsCode: smsCode, maxSmsAttempts: maxSmsAttempts-1 }); 
                });
            })
            .catch((error) => {
                console.log("Error", error);
                dispatch({ type: receiveSMSCodeType, smsCode: -1, maxSmsAttempts: maxSmsAttempts-1, });
            })
            .finally(()=> {
            });
      });

    })
    .catch((error) => {
      console.log("Error", error);      
    })
    .finally(()=> {
    });    
  },

  requesMentors: () => async (dispatch) => {    
    dispatch({ type: requesMentorsType });    
    const url = "/api/v1/adviser/getdefalutdirectors";
    const response = await fetch(url);
    const mentors = await response.json();

    dispatch({ type: receiveMentorsType, mentors });
  },

  setMentor: (adviser) => (dispatch, getState) => {
    
    const mentor = adviser ? adviser : getState().register.finded;
    
    dispatch({ type: setMentorType, regNumber: mentor.RegistrationCode, mentor: {...mentor} });
  },

  requesMentor: refId => async (dispatch) => {
    dispatch({ type: requesMentorType, refId });
    const mentor = await searcMentor(refId);    
    dispatch({ type: receiveMentorType, mentor });  
  },

  requestCountries: () => async (dispatch, getState) => {
    const request = getState().register;
    if (request.allowCountries.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    
    const url = "api/v1/Utils/GetRegionsTwoLetter";
    const response = await fetch(url);
    const allowCountries = await response.json();
    dispatch({ type: receiveCountriesType, allowCountries });

    
      
      // await axios
      //   .get(url)
      //   .then((response) => {          
      //     dispatch({ type: requestCountriesType, deliveryCountries: response.data });
      //   });    
  },

  findMentor: refId => async (dispatch) => {
    dispatch({ type: requesStartType });
    const finded = await searcMentor(refId);    
    dispatch({ type: findMentorType, finded });
  },
  
  changeCustomerState: customerState => async (dispatch) => {
    dispatch({ type: changeCustomerStateType, customerState, Role: customerState === 0 ? "Consultant": "AuthorizedUser" });
  },

  changeField: e => async (dispatch) => {
    dispatch({ type: changeFieldType, fieldName: [e.target.name], value: e.target.value });
  },

  changeCheckBox: e => async (dispatch) => {
    dispatch({ type: changeFieldType, fieldName: [e.target.name], value: e.target.checked });
  },

  changePhoneNumber: phone => async (dispatch) => {
    dispatch({ type: changePhoneType, "PhoneNumber": phone });
  },

  registerUser: () => async (dispatch, getState) => {
    dispatch({ type: requesRegisterType });
    const data = getState().register;
    const model = {      
      UserName: data.Email,
      Email: data.Email,
      PhoneNumber: data.PhoneNumber,
      Password: data.Password,
      ConfirmPassword: data.Password,
      IsMentor: data.IsMentor,
      FirstName: data.FirstName,
      LastName: data.LastName,
      MiddleName: data.MiddleName,
      Birthday: data.Birthday,
      Gender: 0,
      Streetname: "",
      StreetnameNumber: "",
      Apartment: "",
      VillageCityTown: "",
      Raion: "",
      Region: "",
      ZipCode: "",
      Country: data.Country,
      PromoCode: data.PromoCode,
      MentorId: data.mentor.Id,
      Role: data.Role,      
    };

    const url = "/api/v1/account/register";   
    await fetch(url, {
      method: "POST", 
      headers: {          
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8"
      },
      body: JSON.stringify(model)
    })
    .then((response) => {      
      if(response.status !== 200){ 
        response.text().then((text) => {
          dispatch({ type: receiveRegisterType, newUser: null, appError: text });
        })        
      } else {
      response.json().then((data) => {
        // console.log("DATA", data);        
        if( response.status === 200) {
          dispatch({ type: receiveRegisterType, newUser: data, appError: null });        
        }else {
          dispatch({ type: receiveRegisterType, newUser: null, appError: data }); 
        }      
      });
    }
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch({ type: receiveRegisterType, newUser: null, appError: error }); 
    });
    
      // .then((response) => response.json())
      //   .then((data) => {
      //       if(data){                              
      //           dispatch({ type: receiveRegisterType, newUser: data });            
      //       }
      //   })
      // .catch((error) => {
      //   console.log("Error", error);
      // });    
  },

};

export const reducer = (state, action) => {
  state = state || initialState;
    
  switch (action.type) {
    case setMentorType:
      return {
        ...state,
        regNumber: action.regNumber,
        mentor: action.mentor,
      };
    case clearSelectedMentorType:
      return {
        ...state,
        mentor: null,
        isLoading: true
      };
    case requestSMSCodeType:
      return {
        ...state,        
        isLoading: true,
        smsCode: -1,
      };      
    case receiveSMSCodeType:
      return {
        ...state,
        smsCode: action.smsCode,
        maxSmsAttempts: action.maxSmsAttempts,
        timeleft: action.timeleft,
        isLoading: false
      };
    case timeleftRegisterType: 
      return{
        ...state,
        timeleft: action.timeleft
      }
    case requesMentorsType:
    case requesStartType:
    case requesRegisterType:
      return {
        ...state,        
        isLoading: true
      };
    case receiveMentorsType:
      return {
        ...state,        
        mentors: action.mentors,
        isLoading: false
      }; 
    case receiveCountriesType: 
      return {
        ...state,        
        allowCountries: action.allowCountries,
        isLoading: false
      };   
    case requesMentorType:
      return {
        ...state,
        refId: action.refId,
        isLoading: true
      };
    case receiveMentorType:
      return {
        ...state,
        mentor: action.mentor,
        isLoading: false
      };
    case findMentorType:
      return {
        ...state,        
        finded: action.finded,
        isLoading: false
      };
    case changeCustomerStateType:
      return {
        ...state,
        customerState: action.customerState,
        Role: action.Role,
        isLoading: false
      };
    case changeFieldType:      
      return {
          ...state,
          [action.fieldName]: action.value,
          isLoading: false
      };
    case changePhoneType:      
      return {
        ...state,
        PhoneNumber: action.PhoneNumber,
        isLoading: false
      };
    case  receiveRegisterType:
      return {
        ...state,
        newUser: action.newUser,
        appError: action.appError,
        isLoading: false
      };
    default:
      return state;
  }  
};
