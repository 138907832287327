import React, {useContext, useEffect, useCallback, useState} from 'react';
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { useParams } from "react-router-dom";
import CatalogItemLg from "../Catalog/CatalogItemLg";

export const Product = (props) => {
    const app = useContext(AppContext);
    const [item, setItem] = useState();
    const [discounts, setDiscounts] = useState();
    const [token, setToken] = useState();
    const [userName, setUserName] = useState();
    
    const { code } = useParams();

    const getItem =  useCallback( (tkn) => {
        if (!code) return;
        if(!app) return;
                
        const url = `/api/v1/CatalogItem/ByCode/${code}/${app.country}/${app.lang}`;
        console.log('URL', url);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", tkn);

        fetch(url, { method: "GET", headers: headers })
            .then((response) => {
                if(response.status === 200){
                    response.json()
                        .then((catalogItem) => {
                            console.log('CatalogItem', catalogItem);
                            setItem(catalogItem);
                        });
                }else if(response.status === 404){
                    
                }
          })
          .catch(error => {                
                console.log("Error", error);
        });
        
    }, [code, app]);

    useEffect(() => {
        async function feachDiscount (){
            const url = '/data/consultant-discount.json?v=34';
            await fetch(url, { headers: { accept: 'Accept: application/json' } })
                .then((response) => response.json())
                    .then((data) => {
                      if (data) {                
                        // console.log('Discounts', data);
                        setDiscounts(data);
                      }
                    })
                    .catch((error) => {
                      console.log('Error', error);
                    });            
        }

        function fetchUser() {
            if(!app) return;

            app.getUser().then(user => {
                let _token = null;
                if (user != null) {
                    // console.log(user);
                    setUserName(user.profile.name);
                    _token = `Bearer ${user.access_token}`;                     
                }
                getItem(_token);
                setToken(_token);                
            });
        }

        feachDiscount();
        fetchUser();
    }, [app, getItem]);


  return (
    <AppConsumer>
    {() => (
        <>        
        {item && 
            <CatalogItemLg 
                item={item} 
                realated={null} 
                IsAuthenticated={token ? true: false } 
                discounts={discounts} 
                userName={userName}
                country={app.country}
                lang={app.lang}
            />}
        </> 
    )}
    </AppConsumer>
  )
}
